import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovModelsSearchResponse = operations['getModels']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = operations['getModels']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<unknown, unknown, LovModelsSearchResponse>

export async function fetchModels(body: QueryBody) {
  const res = await apiFetcher<LovModelsSearchResponse>(URLS.lovModelsSearch, {
    method: HTTPMethod.POST,
    body
  })

  return res.json
}

export function useLovModelsSearchQuery(
  body: QueryBody = {},
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovModelsSearch(body),
    async () => fetchModels(body),
    { staleTime: Infinity, refetchOnWindowFocus: false, ...opts }
  )
}

export function useModelList(carMakeId?: Maybe<string>) {
  const { data, ...rest } = useLovModelsSearchQuery(
    {
      filter: {
        ids: [],
        makeIds: carMakeId ? [carMakeId] : [],
        yearFromIncludeNull: true,
        active: true
      }
    },
    { enabled: !!carMakeId }
  )

  const models = useMemo(() => {
    return data?.content || []
  }, [data])

  return { ...rest, models }
}

export function useModelDetail(modelId: Maybe<string>) {
  const { data, ...rest } = useLovModelsSearchQuery(
    {
      filter: {
        ids: modelId ? [modelId] : [],
        yearFromIncludeNull: true,
        active: true
      }
    },
    { enabled: !!modelId }
  )
  const model = useMemo(() => {
    return data?.content.find(model => model.id === modelId)
  }, [modelId, data])

  return { ...rest, data, model }
}

export type LovModel = LovModelsSearchResponse['content'][number]
