import {
  useAuctionsSearch,
  useAuctionsSearchQuery
} from 'api/driverama/auctions/searchAuctions'
import { useCarSearch } from 'api/driverama/cars/carSearch'
import {
  LovEnginesSearchResponse,
  useLovEnginesSearchQuery
} from 'api/driverama/lov/lovEnginesSearch'
import {
  LovFuelTypesResponse,
  useLovFuelTypesQuery
} from 'api/driverama/lov/lovFuelTypes'
import { useLovMakesQuery } from 'api/driverama/lov/lovMakes'
import {
  LovModelsSearchResponse,
  useLovModelsSearchQuery
} from 'api/driverama/lov/lovModelsSearch'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isAfter
} from 'date-fns'
import { components as auctionComponents } from 'driverama-core/api/driverama/generated/auctions'
import { components as carsComponents } from 'driverama-core/api/driverama/generated/cars'
import { LovMakesSearchResponse } from 'driverama-core/api/driverama/lov/lovMakesSearch'
import { unique } from 'driverama-core/utils/array'
import {
  ParsedMessageBody,
  Subscription,
  useStompWSClient
} from 'driverama-core/utils/stompWsClient'
import { padWithZeros } from 'driverama-core/utils/strings'
import { isNotNil } from 'driverama-core/utils/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  QueryObserverResult,
  RefetchOptions,
  useQueryClient
} from 'react-query'
import { usePrevious } from 'react-use'
import { useNotificationPermission } from 'utils/permission'
import {
  SearchAuctionBidResponse,
  useSearchAuctionBids
} from '../../api/driverama/auctions/auctionsBids'
import {
  AuctionSearchResponseAgg,
  AuctionSearchResponseAggItem
} from '../../api/driverama/auctions/searchAuctionsAgg'
import {
  PartnersSearchResponse,
  usePartnersList
} from '../../api/driverama/partners/search'
import { HistoryFiltersData } from '../history/filters/HistoryFilters.utils'
import { sortItems } from './sidebar/AuctionsSidebar.utils'

export type AuctionListMode = '15m' | '48h'
export type AuctionListData = AuctionSearchResponseAgg['content'][number] & {
  title: string
  make: string | undefined
  model: string | undefined
  engine: string | undefined
  plannedEndAtDate: Date | undefined
  yearOfMake: number | undefined
  speedometerMileageKm: number | undefined
  fuelType: string | undefined
  powerKw: number | undefined
  volumeCcm: number | undefined
  originCountryId: string | undefined
  opportunityState: auctionComponents['schemas']['OpportunityResponse']['state']
  vin: string | undefined
  licensePlate: string | undefined
  winningBid?: auctionComponents['schemas']['AuctionBidSearchResponse']
  finalBid: number | undefined
  auctionBids:
    | Array<
        auctionComponents['schemas']['AuctionBidResponse'] & {
          partnerName?: string
        }
      >
    | undefined
  relatedAuctions?: AuctionListData[]
}
export type AuctionsSearchData = {
  data: {
    auctions: AuctionListData[] | undefined
    totalElements?: number
    totalPages?: number
  }
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<AuctionSearchResponseAgg | undefined>>
  wsConnected: boolean
}

export interface FilterData extends Partial<HistoryFiltersData> {
  mode?: AuctionListMode
}

export function sortAuctions(
  a: AuctionSearchResponseAgg['content'][number],
  b: AuctionSearchResponseAgg['content'][number]
) {
  const aStart = a.startedAt ? new Date(a.startedAt).getTime() : 0
  const bStart = b.startedAt ? new Date(b.startedAt).getTime() : 0

  if (a.startedAt && !b.startedAt) return -1
  if (!a.startedAt && b.startedAt) return 1
  if (a.startedAt && b.startedAt) return bStart - aStart

  return 0
}

function mapAuction(
  auction: AuctionSearchResponseAggItem,
  cars?: carsComponents['schemas']['CarSearchResponse'][],
  makes?: LovMakesSearchResponse['content'],
  models?: LovModelsSearchResponse['content'],
  engines?: LovEnginesSearchResponse['content'],
  fuelTypes?: LovFuelTypesResponse['content'],
  auctionsBids?: SearchAuctionBidResponse['content'],
  partners?: PartnersSearchResponse['content']
) {
  const car = cars?.find(item => item.id === auction.carId)
  const endDate = getEndDate(auction.plannedEndAt)
  const make = makes?.find(item => item.id === car?.makeId)
  const model = models?.find(item => item.id === car?.modelId)

  const engine = engines?.find(item => item.id === car?.engineId)
  const fuelType = fuelTypes?.find(item => item.id === car?.fuelTypeId)

  const title = [make?.name, model?.name, car?.yearOfMake, engine?.name]
    .filter(str => !!str)
    .join(' ')

  const auctionBids = auctionsBids
    ?.filter(item => item.auctionId === auction.id)
    .filter(bid => bid.state === 'VALID')
    .map(bid => ({
      ...bid,
      partnerName:
        partners?.find(partner => partner.erpId === bid.partnerId)?.name ?? ''
    }))

  return {
    ...auction,
    title,
    make: make?.name,
    model: model?.name,
    engine: engine?.name,
    plannedEndAtDate: endDate,
    yearOfMake: car?.yearOfMake,
    speedometerMileageKm: car?.speedometerMileageKm,
    powerKw: engine?.powerKw,
    volumeCcm: engine?.volumeCcm,
    fuelType: fuelType?.name,
    originCountryId: car?.originCountryId,
    opportunityState: auction.opportunity?.state,
    vin: car?.vin,
    licensePlate: car?.licensePlate,
    winningBid: auction.winningBid,
    finalBid:
      auction.state === 'SUCCESSFUL' ||
      auction.state === 'SUCCESSFUL_WINNER_CHANGED'
        ? auction?.winningBid?.price
        : undefined,
    auctionBids
  }
}

export function useAuctions(filter?: FilterData): AuctionsSearchData {
  const queryClient = useQueryClient()

  const [wsConnected, setWsConnected] = useState(false)
  const isHistoryView = !filter?.mode

  const onWSConnect = () => {
    setWsConnected(true)
  }

  const onWSDisconnect = () => {
    setWsConnected(false)
  }

  const auctions = useAuctionsSearch(
    filter,
    !isHistoryView
      ? {
          refetchOnWindowFocus: false,
          refetchIntervalInBackground: true,
          // when ws is connected we do not need polling, when ws is disconnected, we enable polling so the list would stay fresh
          refetchInterval: wsConnected ? false : 10000
        }
      : {}
  )

  const relatedAuctionsIds = auctions.data?.content.reduce(
    (acc: string[], cur) => {
      return [...acc, ...cur.previousAuctionIdsByCarId]
    },
    []
  )

  const relatedAuctions = useAuctionsSearchQuery(
    {
      ids: unique(relatedAuctionsIds ?? []),
      types: [],
      states: [],
      excludedOpportunityStates: [],
      carMakeIds: [],
      opportunityLossReasons: []
    },
    {
      enabled:
        !!auctions.data?.content.length &&
        !!relatedAuctionsIds?.length &&
        !filter?.mode
    }
  )

  const subscriptions: Subscription[] = useMemo(
    () => [
      {
        topic: '/topic/auction-app',
        onReceivedMessage: async (_, message) => {
          const messageBody: ParsedMessageBody = JSON.parse(message.body)

          // if we receive some auction event, we want to refresh auction list
          await queryClient.invalidateQueries({
            queryKey: [URLS.auctionSearchAgg]
          })
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.auctionsSearch]
          })

          // but only if we receive bid "change" event we want to refresh bid queries
          if (messageBody.type === 'AUCTION_BID_CREATED_OR_UPDATED') {
            await queryClient.invalidateQueries({
              queryKey: [URLS.searchAuctionsBid]
            })
          }

          // refresh diff query
          if (
            messageBody.type === 'AUCTION_RECREATED' ||
            messageBody.type === 'AUCTION_STARTED'
          ) {
            await queryClient.invalidateQueries({
              queryKey: QUERY_KEYS.auctionDiff(messageBody.metadata.auctionId)
            })
          }
        }
      }
    ],
    [queryClient]
  )

  useStompWSClient({
    brokerURL: process.env.NEXT_PUBLIC_STOMP_BROKER_URL as string,
    subscriptions,
    onConnect: onWSConnect,
    onWSDisconnect: onWSDisconnect
  })

  const allAuctions = [
    ...(auctions.data?.content ?? []),
    ...(relatedAuctions.data?.content ?? [])
  ]

  const carsIds = unique(
    allAuctions.map(auction => auction.carId).filter(isNotNil)
  )

  const cars = useCarSearch(
    { ids: carsIds },
    {
      enabled: !!allAuctions.length && !!carsIds.length,
      refetchOnWindowFocus: false
    }
  )

  const opts = {
    enabled: !!cars.data?.content.length,
    refetchOnWindowFocus: false
  }

  const makes = useLovMakesQuery(undefined, opts)
  const models = useLovModelsSearchQuery(
    {
      filter: {
        ids: cars.data?.content.map(item => item.modelId || '') || [],
        yearFromIncludeNull: true
      }
    },
    opts
  )
  const engines = useLovEnginesSearchQuery(
    {
      filter: {
        ids: cars.data?.content.map(item => item.engineId || '') || [],
        yearFromIncludeNull: true
      }
    },
    opts
  )
  const fuelTypes = useLovFuelTypesQuery(undefined, opts)

  const activeAuctions =
    auctions.data?.content
      .filter(auction => auction.state === 'IN_PROGRESS')
      .map(auction => auction.id) ?? []

  const auctionsBids = useSearchAuctionBids(
    {
      auctionIds: activeAuctions
    },
    {
      ...(!isHistoryView
        ? { refetchInterval: wsConnected ? false : 5000 }
        : {}),
      enabled: !!activeAuctions.length
    }
  )

  const partners = usePartnersList()

  const data = useMemo<AuctionListData[] | undefined>(() => {
    if (
      !!auctions.data &&
      !!cars.data &&
      !!makes.data &&
      !!models.data &&
      !!engines.data &&
      !!fuelTypes.data &&
      !!partners.data
    ) {
      return auctions.data.content.map(auction => {
        const relatedAuctionsIds = auction.previousAuctionIdsByCarId

        return {
          ...mapAuction(
            auction,
            cars.data?.content,
            makes.data.content,
            models.data.content,
            engines.data.content,
            fuelTypes.data.content,
            auctionsBids.data?.content,
            partners.data.content
          ),
          relatedAuctions: relatedAuctions.data?.content
            .filter(relatedAuction =>
              relatedAuctionsIds.includes(relatedAuction.id)
            )
            .sort(sortAuctions)
            .map(auction =>
              mapAuction(
                auction,
                cars.data?.content,
                makes.data.content,
                models.data.content,
                engines.data.content,
                fuelTypes.data.content,
                auctionsBids.data?.content,
                partners.data.content
              )
            )
        }
      })
    }

    return undefined
  }, [
    auctions.data,
    cars.data,
    makes.data,
    models.data,
    engines.data,
    fuelTypes.data,
    partners.data,
    auctionsBids.data,
    relatedAuctions.data
  ])

  const queries = [
    auctions,
    cars,
    makes,
    models,
    engines,
    fuelTypes,
    relatedAuctions
  ]

  return {
    data: {
      auctions: data,
      totalElements: auctions.data?.totalElements,
      totalPages: auctions.data?.totalPages
    },
    isLoading: queries.some(query => query.isLoading),
    isFetching: queries.some(query => query.isFetching),
    isError: queries.some(query => query.isError),
    refetch: auctions.refetch,
    wsConnected
  }
}

export function getEndDate(plannedEndAt?: string | null) {
  let endDate: Date | undefined = undefined
  if (plannedEndAt) {
    const newDate = new Date(plannedEndAt)
    const offset = newDate.getTimezoneOffset()
    endDate = new Date(newDate.getTime() - offset * 60000)
  }
  return endDate
}

export function useAuctionNotifications(mode: AuctionListMode, length: number) {
  const { t } = useTranslation(['auction'])
  const [count, setCount] = useState<{
    '15m'?: number
    '48h'?: number
  }>({
    '15m': undefined,
    '48h': undefined
  })
  const prevCount = usePrevious(count)
  const permission = useNotificationPermission()

  useEffect(() => {
    if (length > (count[mode] ?? 0)) {
      setCount(prev => {
        const newCount = { ...prev }
        newCount[mode] = length
        return newCount
      })
    }
  }, [count, length, mode])

  useEffect(() => {
    if (
      permission === 'granted' &&
      (count[mode] ?? Number.POSITIVE_INFINITY) >
        ((prevCount && prevCount[mode]) ?? Number.POSITIVE_INFINITY)
    ) {
      new Notification(t('auction:notification_new_title'), {
        body: t('auction:notification_new_body'),
        icon: 'android-chrome-192x192.png'
      })
    }
  }, [count, mode, permission, prevCount, t])
}

export function useAuctionAutoSelect(
  data: AuctionListData[] | undefined,
  isLoading: boolean,
  selected: AuctionListData | undefined,
  onSelected: (value: AuctionListData | undefined) => void
) {
  const initialSelect = useRef(true)

  useEffect(() => {
    if (!isLoading) {
      if (data?.length && (!selected || initialSelect.current)) {
        onSelected(data.sort(sortItems)[0])
        initialSelect.current = false
        return
      }

      if (
        !data?.length ||
        (selected && !data.find(a => a.id === selected.id))
      ) {
        onSelected(undefined)
        initialSelect.current = true
        return
      }
    }
  }, [data, isLoading, onSelected, selected])
}

export function createBidButtonTimerString(reservationDate: Date) {
  const currentDate = new Date()
  if (isAfter(currentDate, reservationDate)) {
    return '00:00'
  }

  const hours = differenceInHours(reservationDate, currentDate)
  const hoursInMinutes = hours * 60
  const hoursInSeconds = hoursInMinutes * 60

  const minutes =
    differenceInMinutes(reservationDate, currentDate) - hoursInMinutes
  const minutesInSeconds = minutes * 60

  const seconds =
    differenceInSeconds(reservationDate, currentDate) -
    minutesInSeconds -
    hoursInSeconds

  const paddedMinutes = padWithZeros(minutes, 2)
  const paddedSeconds = padWithZeros(seconds, 2)

  // return string without padded hours
  if (hours === 0) {
    return [paddedMinutes, paddedSeconds].join(':')
  }

  const paddedHours = padWithZeros(hours, 2)

  return [paddedHours, paddedMinutes, paddedSeconds].join(':')
}
