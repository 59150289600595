import { components } from 'driverama-core/api/driverama/generated/auctions'
import { TFunction } from 'react-i18next'

type Source = components['schemas']['OpportunityResponse']['source']

export function getSourceTranslation(
  source: Source,
  t: TFunction<('core' | 'auction')[]>
) {
  switch (source) {
    case 'WALK_IN':
      return t('core:source_WALK_IN')
    case 'E_COMMERCE':
      return t('core:source_E_COMMERCE')
    case 'TICKING':
      return t('core:source_TICKING')
    case 'IN_CALL':
      return t('core:source_IN_CALL')
    case 'PROACTIVITY':
      return t('core:source_PROACTIVITY')
    case 'B2B':
      return t('core:source_B2B')
    default:
      return source
  }
}
