import { Client, IFrame, IMessage, StompHeaders } from '@stomp/stompjs'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'

export interface Subscription {
  topic: string
  onReceivedMessage: (topic: string, message: IMessage) => Promise<void>
}

interface UseStompWSClientProps {
  brokerURL: string
  connectHeaders?: StompHeaders
  reconnectDelay?: number
  heartbeatIncoming?: number
  heartbeatOutgoing?: number
  subscriptions: Subscription[]
  onConnect?: (frame: IFrame) => void
  onWSDisconnect?: () => void
}

export function useStompWSClient({
  brokerURL,
  connectHeaders,
  reconnectDelay = 5000,
  heartbeatIncoming = 4000,
  heartbeatOutgoing = 4000,
  subscriptions,
  onConnect,
  onWSDisconnect
}: UseStompWSClientProps) {
  const [messages, setMessages] = useState<IMessage[]>([])

  useEffectOnce(() => {
    const stompClient = new Client({
      brokerURL,
      connectHeaders: {
        ...connectHeaders,
        Accept: 'application/com.driverama-v1+json'
      },
      reconnectDelay,
      heartbeatIncoming,
      heartbeatOutgoing
    })

    stompClient.onConnect = frame => {
      onConnect?.(frame)

      subscriptions.forEach(subscription => {
        const { topic, onReceivedMessage } = subscription

        return stompClient.subscribe(topic, msg => {
          onReceivedMessage(topic, msg)
          setMessages(prev => [...prev, msg])
        })
      })
    }

    stompClient.onStompError = function (frame) {
      // Complaint brokers will set `message` header with a brief message. Body may contain details.
      // Compliant brokers will terminate the connection after any error
      console.log('Broker reported error: ' + frame.headers['message'])
      console.log('Additional details: ' + frame.body)
    }

    stompClient.onWebSocketError = function (evt) {
      onWSDisconnect?.()
    }

    stompClient.activate()

    return () => {
      if (stompClient) {
        stompClient.deactivate()
      }
    }
  })

  return { messages }
}

type AuctionGeneralEventType =
  | 'AUCTION_INITIATED'
  | 'AUCTION_STARTED'
  | 'AUCTION_ENDED'
  | 'AUCTION_RECREATED'

export type ParsedMessageBody =
  | {
      type: AuctionGeneralEventType
      metadata: {
        auctionId: string
      }
    }
  | {
      type: 'AUCTION_BID_CREATED_OR_UPDATED'
      metadata: {
        auctionId: string
        auctionBidId: string
      }
    }
