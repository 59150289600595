import { URLS } from 'constants/api'
import { paths } from 'driverama-core/api/driverama/generated/auctions'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { createAcceptHeaderWithVersion } from 'driverama-core/utils/fetcher'
import { omitNilValues } from 'driverama-core/utils/object'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { QUERY_KEYS } from '../../../constants/queryKeys'

type PutAuctionBidBody = paths['/auctions/{auctionId}/bids']['put']['requestBody']['content']['application/json']
type PutAuctionBidResponse = paths['/auctions/{auctionId}/bids']['put']['responses']['200']['content']['application/com.driverama-v2+json']

export async function putAuctionBid(
  auctionId: string | number | undefined | null,
  body?: PutAuctionBidBody
) {
  return apiAuthFetcher<PutAuctionBidResponse>(
    URLS.auctionsBid(auctionId != null ? `${auctionId}` : undefined),
    {
      method: HTTPMethod.PUT,
      headers: {
        Accept: createAcceptHeaderWithVersion(2)
      },
      body
    }
  )
}

type SearchAuctionBidBody = paths['/auctions/bids/search']['post']['requestBody']['content']['application/json']
export type SearchAuctionBidResponse = paths['/auctions/bids/search']['post']['responses']['200']['content']['application/com.driverama-v1+json']
type SearchAuctionBidQueryOpts = UseQueryOptions<
  unknown,
  unknown,
  SearchAuctionBidResponse
>

export function useSearchAuctionBids(
  body: SearchAuctionBidBody,
  opts?: SearchAuctionBidQueryOpts,
  params?: {
    sort: [keyof SearchAuctionBidResponse['content'][number], 'asc' | 'desc']
  }
) {
  return useQuery(
    QUERY_KEYS.auctionBidsSearch(params, body),
    async () => {
      const res = await apiAuthFetcher<SearchAuctionBidResponse>(
        URLS.searchAuctionsBid,
        {
          method: HTTPMethod.POST,
          body,
          searchParams: omitNilValues({
            sort: params?.sort ? params.sort.join(',') : null
          })
        }
      )
      return res.json
    },
    {
      refetchOnWindowFocus: false,
      ...opts
    }
  )
}
