import { paths } from 'driverama-core/api/driverama/generated/employees'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { URLS } from '../../../constants/api'
import { QUERY_KEYS } from '../../../constants/queryKeys'

export type EmployeesSearchResponse = paths['/employees/search']['post']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = paths['/employees/search']['post']['requestBody']['content']['application/json']
type QueryOpts = UseQueryOptions<unknown, unknown, EmployeesSearchResponse>

export function useEmployeesSearchQuery(body: QueryBody, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.employeesSearch(body),
    async () => {
      const res = await apiAuthFetcher<EmployeesSearchResponse>(
        URLS.employeesSearch,
        {
          method: HTTPMethod.POST,
          body
        }
      )
      return res.json
    },
    {
      refetchOnWindowFocus: false,
      ...opts
    }
  )
}
